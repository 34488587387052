export function convertTrigger(trigger) {
  let name_trigger = "";
  switch (trigger) {

    //  vendas
    case "salePaid": {
      name_trigger = "Venda Paga";
      break;
    }
    case "saleRefused": {
      name_trigger = "Venda Recusada";
      break;
    }
    case "saleRefunded": {
      name_trigger = "Venda Reembolsada";
      break;
    }
    case "BOLETO": {
      name_trigger = "Boleto";
      break;
    }
    case "PIX": {
      name_trigger = "PIX";
      break;
    }
    case "saleChargedback": {
      name_trigger = "Chargeback realizado";
      break;
    }

    case "saleUnpaid": {
      name_trigger = "Venda não paga";
      break;
    }
    case "saleWaitingPayment": {
      name_trigger = "Venda aguardando pagamento";
      break;
    }
    //  assinaturas
    case "contractUnpaid": {
      name_trigger = "Assinatura não paga";
      break;
    }
    case "contractPaid": {
      name_trigger = "Assinatura paga";
      break;
    }
    case "contractTrialing": {
      name_trigger = "Assinatura em Trial";
      break;
    }
    case "contractPendingPayment": {
      name_trigger = "Assinatura aguardando pagamento";
      break;
    }
    case "contractEnded": {
      name_trigger = "Assinatura Finalizada ";
      break;
    }
    //  checkout
    case "checkoutAbandoned": {
      name_trigger = "Checkout Abandonado";
      break;
    }

    //  venda recusada

    case "INVALID_CREDIT_CARD": {
      name_trigger = "Cartão inválido/bloqueado";
      break;
    }

    case "INSUFFICIENT_FUNDS": {
      name_trigger = "Cartão sem limite";
      break;

    }

    case "INVALID_PAYMENT_TYPE": {
      name_trigger = "Método inválido";
      break;

    }

    case "INVALID_DATA": {
      name_trigger = "Dados inválidos";
      break;

    }

    case "INVALID_CLIENT_DATA": {
      name_trigger = "Dados do cliente inválidos";
      break;

    }

    case "INVALID_CVV": {
      name_trigger = "CVV inválido";
      break;

    }

    case "BANK": {
      name_trigger = "Banco emissor do cartão";
      break;

    }

    case "CREDIT_CARD_OPERATOR": {
      name_trigger = "Operadora do cartão";
      break;

    }

    case "INVALID_INSTALLMENTS": {
      name_trigger = "Qtd parcelas não autorizada";
      break;

    }

    case "CURRENCY_NOT_SUPPORTED": {
      name_trigger = "Moeda não autorizada";
      break;

    }

    case "SUSPECTED_FRAUD": {
      name_trigger = "Suspeita de Fraude";
      break;

    }

    case "GENERIC": {
      name_trigger = "Erro ao processar a compra";
      break;

    }


    default: {
      name_trigger = trigger;
      break;
    }
  }
  return name_trigger;
}

export function textMethod(method) {
  let t = "";
  if (method) {
    switch (method.toUpperCase()) {
      case "CREDIT_CARD": {
        t = "CARTÃO DE CRÉDITO";
        break;
      }
      case "TWO_CREDIT_CARDS": {
        t = "DOIS CARTÕES";
        break;
      }

      default: {
        t = method;
        break;
      }
    }
    return t;
  } else return method;
}

export function iconMethod(method) {
  let icon = "";
  switch (method) {
    case "CREDIT_CARD": {
      icon = "credit-card";
      break;
    }
    case "BOLETO": {
      icon = "barcode";
      break;
    }
    case "PIX": {
      icon = "qrcode";
      break;
    }
    case "PAYPAL": {
      icon = ["fab", "paypal"];
      break;
    }
  }
  return icon;
}