<template>
  <BaseModal name="contracts-filters" size="lg" title="Filtros">
    <b-row>
      <b-col cols="12">
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group label="Produtos">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Pesquise o Produto"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_product_debounce"
                >
                  <span slot="noOptions"
                    >Lista está vazia. Pesquise pelo produto</span
                  >

                  <span slot="noResult"
                    >Oops! Nenhum produto encontrado. Pesquise outro termo</span
                  >
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Afiliados">
                <multiselect
                  v-model="filter.affiliates"
                  placeholder="Selecione o Afiliado"
                  label="name"
                  track-by="id"
                  :options="affiliates"
                  :multiple="true"
                  :taggable="false"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum afiliado encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Método de pagamento"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('metodos')"
                  class="btn-todos"
                  >Todos os métodos</a
                >
                <multiselect
                  v-model="filter.method"
                  placeholder="Método de pagamento "
                  label="name"
                  track-by="id"
                  :options="method_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum método encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Status"
                label-for="status"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('status')"
                  class="btn-todos"
                  >Todos os stauts</a
                >
                <multiselect
                  v-model="filter.status"
                  placeholder="Status "
                  label="text"
                  track-by="value"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  class="multi-select-input"
                  ><span slot="noResult"
                    >Nenhum status encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="secondary" @click="onSubmit"> Filtrar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import ContractService from "@/services/resources/ContractService";
const serviceContract = ContractService.build();

import _ from "lodash";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: false,
      search: "",
      products: [],
      affiliates: [],
      filter: {
        products: [],
        affiliates: [],
        type: "created_at",
        status: [
          { value: "trialing", text: "Em teste" },
          { value: "paid", text: "Pago" },
          { value: "pending_payment", text: "Atrasado" },
          { value: "unpaid", text: "Não pago" },
          { value: "canceled", text: "Cancelado" },
          { value: "ended", text: "Encerrado" },
        ],
        method: [
          { id: "CREDIT_CARD", name: "Cartão de crédito" },
          { id: "BOLETO", name: "Boleto" },
          // { id: "PIX", name: "Pix" },
          // { id: "PAYPAL", name: "PayPal" },
          // { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" },
        ],
      },

      status_list: [
        { value: "trialing", text: "Em teste" },
        { value: "paid", text: "Pago" },
        { value: "pending_payment", text: "Atrasado" },
        { value: "unpaid", text: "Não pago" },
        { value: "canceled", text: "Cancelado" },
        { value: "ended", text: "Encerrado" },
      ],

      method_list: [
        { id: "CREDIT_CARD", name: "Cartão de crédito" },
        { id: "BOLETO", name: "Boleto" },
        // { id: "PIX", name: "Pix" },
        // { id: "PAYPAL", name: "PayPal" },
        // { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" },
      ],
    };
  },

  methods: {
    selecionarTodos(type) {
      switch (type) {
        case "metodos": {
          this.filter.method = [
            { id: "CREDIT_CARD", name: "Cartão de crédito" },
            { id: "BOLETO", name: "Boleto" },
          ];
          break;
        }
        case "status": {
          this.filter.status = [
            { value: "trialing", text: "Em teste" },
            { value: "paid", text: "Pago" },
            { value: "pending_payment", text: "Atrasado" },
            { value: "unpaid", text: "Não pago" },
            { value: "canceled", text: "Cancelado" },
            { value: "ended", text: "Encerrado" },
          ];
          break;
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function() {
      this.fetchProducts(this.search);
    }, 500),

    onSubmit() {
      let data = {
        page: 1,
        type: this.filter.type,
        status: this.filter.status.length
          ? this.filter.status.map((item) => item.value)
          : null,
        method: this.filter.method.length
          ? this.filter.method.map((item) => item.id)
          : null,
      };
      if (this.filter.affiliates.length) {
        this.filter.affiliates = this.filter.affiliates.map((item) => item.id);
      }

      if (this.filter.affiliates.length) {
        data.affiliates = this.filter.affiliates.map((item) => item.id);
      }

      let products = [];
      if (this.filter.products.length) {
        this.filter.products.forEach((item) => {
          products.push({ "contracts[*][product_id]": item.id });
        });

        const newProducts = [];
        Object.keys(products).forEach((key) => {
          products[key] == null && delete products[key];
          let aux = JSON.stringify(products[key]);
          if (aux.indexOf("*")) {
            let arr = aux.split("");
            arr.splice(arr.indexOf("*"), 1, key);
            newProducts.push(JSON.parse(arr.join().replaceAll(",", "")));
          }
        });

        data = Object.assign(data, ...newProducts);
      }

      // this.$emit("filter", data);
      this.$root.$emit("updateDataFilter", data);
      this.$bvModal.hide("contracts-filters");
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
      };

      if (search) data.search = search;

      this.products = [];

      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchAffiliates() {
      let data = {
        id: "/affiliate",
      };

      this.affiliates = [];

      serviceContract
        .read(data)
        .then((response) => {
          response.forEach((element) => {
            this.affiliates.push({
              id: element.id,
              name: `${element.name}  - ${element.email}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
          this.affiliates = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAffiliates();
  },
};
</script>

<style lang="scss">
.w-100 {
  width: 100%;
}
.daterangepicker {
  top: 100% !important;
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
