<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-contract"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel">
          <b-col>
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="label-campo">Código</p>
                      <p class="dados-campo">{{ contract.id }}</p>
                    </b-col>
                    <b-col cols="4">
                      <p class="label-campo">Status</p>
                      <p
                        class="dados-campo"
                        :class="getStatus(contract.status).class"
                      >
                        {{ getStatus(contract.status).text }}
                      </p>
                    </b-col>

                    <b-col cols="4">
                      <p class="label-campo">Início</p>
                      <p class="dados-campo" v-if="contract.start_date">
                        {{ contract.start_date | date }}
                      </p>
                    </b-col>
                  </b-row>

                  <b-row v-if="contract.current_period_end">
                    <b-col cols="6">
                      <p class="label-campo">Prox. Vencimento</p>
                      <p class="dados-campo">
                        {{ contract.current_period_end | date }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-col cols="5" class="mt-2">
                    <p class="label-campo">Método</p>
                    <p class="dados-campo">
                      {{
                        contract.history.length
                          ? getMethod(contract.history[0].method)
                          : "Sem método"
                      }}
                    </p>
                  </b-col>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row class="item-dados">
                <b-col>
                  <h5 class="titulo-item">Produto</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          :src="contract.contract.live.product.images[0].path"
                          v-if="
                            !loading &&
                              contract.contract.live.product &&
                              contract.contract.live.product.images.length
                          "
                        />
                      </div>
                      <div
                        class="name-product"
                        v-if="!loading && contract.contract.live.product"
                      >
                        <span
                          >Produto: #{{
                            contract.contract.live.product.id
                          }}</span
                        >
                        <p>{{ contract.contract.live.product.name }}</p>
                        <p class="hash" v-if="contract.contract.offer">
                          Código da Oferta: {{ contract.contract.offer.hash }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row>

              <!-- Affiliate -->
              <b-row v-if="contract.affiliate" class="item-dados">
                <b-col>
                  <h5 class="titulo-item">Afiliado</h5>
                  <p class="label-campo">
                    <span class="title-item">Nome</span>
                    <span>{{ contract.affiliate.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Endereço de e-mail</span>
                    <span>{{ contract.affiliate.email }}</span>
                  </p>
                </b-col>
              </b-row>

              <!-- Client -->
              <b-row class="item-dados">
                <b-col v-if="contract.client">
                  <h5 class="titulo-item">Cliente</h5>
                  <p class="label-campo">
                    <span class="title-item">Nome</span>
                    <span>{{ contract.client.name }}</span>
                  </p>
                  <p class="label-campo" v-if="isSeller">
                    <span class="title-item">Endereço de e-mail</span>
                    <span>{{ contract.client.email }}</span>
                  </p>
                  <p class="label-campo" v-if="isSeller">
                    <span class="title-item">Telefone</span>

                    <a
                      :href="formatTel(contract.client.cellphone)"
                      target="_blank"
                      class="whats-cliente"
                      >{{ contract.client.cellphone
                      }}<font-awesome-icon :icon="['fab', 'whatsapp']"
                    /></a>
                  </p>
                </b-col>
              </b-row>

              <!-- transações -->
              <b-row class="item-dados">
                <b-col>
                  <h5
                    class="titulo-item titulo-arrow"
                    v-b-toggle.collapse-2
                    @click="collapse = !collapse"
                    :class="{ ativo: collapse }"
                  >
                    Transações
                    <img src="@/assets/img/icons/arrow-fill.svg" alt="" />
                  </h5>

                  <b-collapse id="collapse-2" data-anima="top">
                    <b-row class="mt-3 mb-1">
                      <div class="label-campo"></div>
                      <b-col cols="2" class="titulo-collapse label-campo">
                        Fatura
                      </b-col>

                      <b-col cols="3" class="titulo-collapse label-campo">
                        Criação
                      </b-col>

                      <b-col cols="4" class="titulo-collapse label-campo">
                        Pagamento
                      </b-col>

                      <b-col cols="3" class="titulo-collapse label-campo">
                        Valor
                      </b-col>
                    </b-row>

                    <!-- -- -->
                    <div v-if="contract.history && contract.history.length">
                      <div
                        v-for="item in contract.history"
                        :key="item.id"
                        class="history-item"
                        :class="getStatus_sales(item.status).class"
                        v-b-tooltip="{
                          title: getStatus_sales(item.status).text,
                          placement: 'right',
                        }"
                      >
                        <b-row>
                          <b-col
                            cols="2"
                            class="label-campo titulo-collapse mt-1"
                          >
                            <span>{{ item.id }} </span></b-col
                          >

                          <b-col cols="3" class="label-campo titulo-collapse">
                            <span> {{ item.created_at | date }}</span>
                          </b-col>

                          <b-col cols="4" class="label-campo titulo-collapse">
                            <span v-if="item.paid_at">
                              {{ item.paid_at | date }}</span
                            >
                            <span v-else>-</span>
                          </b-col>

                          <b-col cols="3" class="label-campo titulo-collapse">
                            <span v-if="item.balance">
                              R$ {{ formatMoney(item.balance) }}</span
                            >
                            <a
                              :href="item.boleto_url"
                              target="_blank"
                              v-if="
                                item.method == 'BOLETO' &&
                                  item.status != 'paid' &&
                                  item.status != 'refunded'
                              "
                              >Abrir boleto</a
                            >

                            <span
                              v-if="
                                !item.balance &&
                                  item.method != 'BOLETO' &&
                                  item.status != 'paid'
                              "
                            >
                              R$ 0,00</span
                            >
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div v-else>
                      <p class="sem-historico">Nenhum histórico de transação</p>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>

              <!-- Options -->
              <b-row class="item-dados">
                <b-col>
                  <h5 class="titulo-item">Opções</h5>
                  <div class="btn-flex">
                    <BaseConfirmDialog
                      class="d-inline-block"
                      @confirmed="cancelContract"
                      id="btn-cancela-contrato"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="accent_outline"
                          :outline="true"
                          :disabled="
                            contract.status == 'canceled' ||
                              contract.status == 'CANCELED' ||
                              contract.status == 'PROCESSING' ||
                              is_btn_disable
                          "
                          @click="openModal"
                        >
                          {{
                            contract.status == "PROCESSING" ||
                            contract.status == "processing"
                              ? "Processando Cancelamento"
                              : "Encerrar Contrato"
                          }}
                        </BaseButton>
                      </template>
                    </BaseConfirmDialog>

                    <b-tooltip
                      v-if="
                        contract.status == 'canceled' ||
                          contract.status == 'CANCELED'
                      "
                      target="btn-cancela-contrato"
                      title="Contrato já consta encerrado"
                    />

                    <Affiliation
                      v-if="
                        (contract.seller_id == userID &&
                          !contract.affiliate &&
                          contract.status == 'ACTIVE') ||
                          contract.status == 'paid'
                      "
                      :product_id="contract.contract.product_id"
                      from="contract"
                      @close="fetchContract"
                      class="d-inline-block"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="secondary"
                          :outline="true"
                          @click="openModal(contract_id)"
                          >Atribuir Afiliado</BaseButton
                        >
                      </template>
                    </Affiliation>

                    <Subscription
                      v-if="
                        contract.seller_id == userID &&
                          contract.status == 'ACTIVE'
                      "
                      @close="fetchContract"
                      class="d-inline-block"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="info3"
                          @click="openModal(contract_id)"
                          >Alterar Assinatura</BaseButton
                        >
                      </template>
                    </Subscription>
                    <div>
                      <BaseButton variant="secondary" @click="openSalesPage()"
                        >Atualizar Assinatura</BaseButton
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>
<script>
import ContractService from "@/services/resources/ContractService";
// import Affiliation from "./modals/Affiliation";
import Affiliation from "@/components/shared/modals/Affiliation";
import Subscription from "./modals/Subscription";
import moment from "moment";
import Money from "@/mixins/money";
import { textMethod } from "@/helpers.js";

const service = ContractService.build();

export default {
  name: "DetalhesContrato",
  components: {
    Affiliation,
    Subscription,
  },
  mixins: [Money],
  data() {
    return {
      loading: true,
      collapse: false,
      is_btn_disable: false,
      contract_id: -1,
      visible: false,
      contract: {
        contract: {
          live: {
            product: {
              name: "",
              description: "",
              images: [{ path: null }],
            },
          },
        },
        start_date: "",
        client: {
          name: null,
          email: null,
          cellphone: null,
        },
        history: [
          {
            status: "processing",
            updated_at: "",
          },
        ],
      },
    };
  },
  filters: {
    date(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  computed: {
    userID() {
      return this.$store.getters.getProfile.id;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    salesPage() {
      const id_produto = this.contract.contract.live.product.id;
      let slug = `${id_produto}/?`;

      /* if (this.contract.contract.proposal_id) {
        slug = `${id_produto}/?prop_id=${this.contract.contract.proposal_id}&`;
      } else {
        slug = `${id_produto}/?`;
      } */

      return (
        process.env.VUE_SALES_PAGE +
        "/assinatura/" +
        slug +
        "fn=" +
        encodeURI(this.contract.client.name) +
        "&document=" +
        encodeURI(this.contract.client.cpf_cnpj) +
        "&client_id=" +
        this.contract.client_id
      );
    },
  },
  methods: {
    getMethod(method) {
      return textMethod(method);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "trialing": {
            r = {
              text: "Em Trial",
              class: "icon-warning",
            };
            break;
          }
          case "processing": {
            r = {
              text: "Processando",
              class: "icon-warning",
            };
            break;
          }
          case "paid": {
            r = { text: "Em Dia", class: "icon-green" };
            break;
          }
          case "pending_payment": {
            r = { text: "Atrasado", class: "icon-warning" };
            break;
          }
          case "unpaid": {
            r = { text: "Não pago", class: "icon-red" };
            break;
          }
          case "canceled": {
            r = { text: "Cancelado", class: "icon-red" };
            break;
          }
          case "ended": {
            r = { text: "Encerrado", class: "icon-red" };
            break;
          }

          default: {
            r = { text: status, class: "icon-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "icon-black" };
    },
    getStatus_sales(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": {
            r = { text: "Processando", class: "is-light-blue" };
            break;
          }
          case "authorized": {
            r = { text: "Transação autorizada", class: "is-light-blue" };
            break;
          }
          case "paid": {
            r = {
              text: "Em Dia",
              class: "is-green",
            };
            break;
          }
          case "refunded": {
            r = { text: "Estornado", class: "is-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: "Aguardando pagamento", class: "is-warning" };
            break;
          }
          case "pending_refund": {
            r = { text: "Reembolso pendente", class: "is-warning" };
            break;
          }
          case "refused": {
            r = { text: "Rejeitado", class: "is-red" };
            break;
          }
          case "chargedback": {
            r = { text: "Estorno", class: "is-warning" };
            break;
          }
          case "delayed": {
            r = { text: "Em processamento", class: "is-warning" };
            break;
          }
          default: {
            r = { text: status, class: "is-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    openSalesPage() {
      window.open(this.salesPage);
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
    openPanel(contract_id) {
      this.is_btn_disable = false;
      this.visible = true;
      this.contract_id = contract_id;
      this.fetchContract();
    },
    cancelContract() {
      let data = {
        id: this.contract.id,
      };

      service.destroy(data).then((response) => {
        if (response.success) {
          this.is_btn_disable = true;

          this.$bvToast.toast(
            "O pedido de cancelamento do contrato será processado",
            {
              title: "Contrato",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.fetchContract();
        } else {
          this.$bvToast.toast(
            "Houve um erro ao solicitar o cancelamento deste contrato",
            {
              title: "Contrato",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        }
      });
    },
    fetchContract() {
      this.contract = {
        contract: {
          live: {
            product: {
              name: "",
              description: "",
              images: [{ path: null }],
            },
          },
        },
      };

      this.loading = true;

      let data = {
        id: this.contract_id,
      };

      service.read(data).then((response) => {
        this.loading = false;
        this.contract = response;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  .sumary {
    font-size: 13px;
    // padding-top: 20px;
    // padding-bottom: 20px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
  }

  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }

  .product-description {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #81858e;
    font-weight: normal;
  }
}
.name-product .hash {
  font-size: 12px;
  color: #81858e;
  font-weight: inherit;
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 11px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.label-campo + .label-campo {
  margin-top: 5px;
}
.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button {
  width: 100%;
  height: 55px;
}
.btn-flex div button:hover {
  transform: none;
}

// collapse
.titulo-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.titulo-arrow.ativo {
  margin-bottom: 15px;
}
.titulo-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}

// history
.titulo-collapse {
  font-size: 13px !important;
}

.history-item {
  padding: 15px 0;
  position: relative;
}
.history-item::before {
  left: -10px;
  top: 50%;
}

.history-item + .history-item {
  // padding-top: 15px;
  border-top: 1px solid #ededf0;
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: #81858e;
}
.whats-cliente {
  font-size: 14px;
  color: #2a63ab;
}
.whats-cliente svg {
  margin-left: 5px;
}

.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: #81858e;
}
.name-product p {
  font-weight: 600;
}
</style>
